import React from 'react'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
} from '../components/ProjectsInner'
import { FormOrder, Breadcrumbs } from '../components/Global'
import { graphql } from "gatsby";
// content
import YAMLGlobal from "../content/ru/global.yaml"

const ProjectsInnerPage = ({ data, location }) => {
    const project = data.allDirectusOurProject.edges[0].node;
    const projectsLocal = {
        langBase: "",
        YAMLGlobal,
    }
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: `/projects/category/${project.category.slug}`,
            title: project.category.title
        },
        {
            link: `/projects/${project.slug}`,
            title: project.title
        }
    ]
    
    return (
        <Layout local={projectsLocal} location={location}>
            <SEO
              title={project.title}
              image={project.title_photo.localFile?.childImageSharp.fluid.src}
            />
            <Breadcrumbs list={list}/>
            <FirstScreen project={project}/>
            <FormOrder wave="white"/>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        allDirectusOurProject (filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    slug
                    title
                    content
                    category {
                        slug
                        title
                    }
                    title_photo {
                        id
                        localFile {
                            childImageSharp {
                                fluid {
                                    src
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    photos {
                        id
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ProjectsInnerPage;