import React, { useState, useContext } from 'react';
import { Link } from "gatsby";
import Img from 'gatsby-image';
import classNames from 'classnames';
// import PropTypes from 'prop-types'
// ui-kit
import {
    Container,
    Button,
    Title,
    Row
} from '../../../ui-kit'
// styles
import s from './FirstScreen.module.sass'
// svg
import Circles from '../../../icons/blog-inner/circles.svg'
// context
import {LayoutContext} from '../../Layouts/layout'

export default function FirstScreen({project}) {
    const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);
    
    const [mainImg, setMainImg] = useState(project.title_photo.localFile?.childImageSharp.fluid);
    
    return (
        <section className={s.firstScreen}>
            <Container>
                {/* <Title type="page" className={s.title}>{project.title || project.title_ua}</Title> */}
                <div className={s.wrapper}>
                    <div className={s.imgWrapper}>
                        <div className={s.square}></div>
                        <div className={s.circles}><Circles/></div>
                        <div>
                            <div className={s.inner}>
                                <div className={s.img}>
                                    <Img fluid={mainImg} className={s.gatsby} />
                                </div>
                            </div>
                            <div className={s.thumbnails}>
                              <div className={s.thumbnail}>
                                <div className={s.timg} onClick={() => {setMainImg(project.title_photo.localFile?.childImageSharp.fluid)}}>
                                  <Img fluid={project.title_photo.localFile?.childImageSharp.fluid} className={s.gatsby} />
                                </div>
                              </div>
                                {
                                    project.photos.map((photo) => {
                                        return (
                                            <div className={s.thumbnail} key={photo.id}>
                                                <div className={s.timg} onClick={() => {setMainImg(photo.localFile?.childImageSharp.fluid)}}>
                                                    <Img fluid={photo.localFile?.childImageSharp.fluid} className={s.gatsby} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }                                
                            </div>
                        </div>
                    </div>
                    <div className={s.content}>
                        <Title className={s.title_s}>{project.title || project.title_ua}</Title>
                        <div dangerouslySetInnerHTML={{__html: project.content || project.content_ua}}/>
                    </div>
                    <div className={s.clear}></div>
                </div>
                <Row justify="justify-content-center">
                    <Link to={`${langBase}/projects/category/${project.category.slug}`}><Button className={classNames("green", s.button)}>{YAMLGlobal.button.projects}</Button></Link>
                </Row>
            </Container>
        </section>
    )
}